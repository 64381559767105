<template>
	<a-card :bordered="false">
		<div class="table-page-search-wrapper">
			<a-form layout="inline" @submit="handleSubmit" :form="form">
				<a-row :gutter="48">
					<a-col :md="8" :sm="24">
						<a-form-item label="用户类型">
							<a-select placeholder="请选择" v-decorator="['status', { initialValue: queryParam.status }]">
								<a-select-option value="">全部</a-select-option>
								<a-select-option value="Open">进行中</a-select-option>
								<a-select-option value="AdminReply">已回复</a-select-option>
								<a-select-option value="Close">已关闭</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<span class="table-page-search-submitButtons">
							<a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
							<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
						</span>
					</a-col>
				</a-row>
			</a-form>
		</div>
		<a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" :pagination="pagination"
			@change="handleTableChange" rowKey="id">
			<span slot="user_type" slot-scope="text">
				{{ text | filterUserType }}
			</span>
			<span slot="status" slot-scope="text">
				{{ text | filterStatus }}
			</span>
			<span slot="create_time" slot-scope="text">
				{{ text | dayjs }}
			</span>
			<span slot="action" slot-scope="text, record">
				<a @click="goWorkOrderDetails(record.id)">查看</a>
			</span>
		</a-table>
	</a-card>
</template>

<script>
import merge from 'webpack-merge'

const columns = [{
	title: 'ID',
	dataIndex: 'id'
},
{
	title: '账户ID',
	dataIndex: 'account_id'
},
{
	title: '用户类型',
	dataIndex: 'user_type',
	scopedSlots: {
		customRender: 'user_type'
	}
},
{
	title: '昵称',
	dataIndex: 'nick_name'
},
{
	title: '主题',
	dataIndex: 'title'
},
{
	title: '状态',
	dataIndex: 'status',
	scopedSlots: {
		customRender: 'status'
	}
},
{
	title: '创建时间',
	dataIndex: 'create_time',
	scopedSlots: {
		customRender: 'create_time'
	}
},
{
	title: '操作',
	scopedSlots: { customRender: 'action' }
}
]

export default {
	name: 'EmailList',
	data() {
		return {
			description: '展示用户提交的工单，管理员进行处理并回复',
			labelCol: {
				xs: {
					span: 24
				},
				sm: {
					span: 5
				}
			},
			wrapperCol: {
				xs: {
					span: 24
				},
				sm: {
					span: 16
				}
			},
			// 高级搜索 展开/关闭
			advanced: false,
			// form
			form: this.$form.createForm(this),
			// 查询参数
			queryParam: {
				user_type: this.$route.query.user_type,
				status: (this.$route.query.status ? this.$route.query.status : '')
			},
			columns,
			tableData: [],
			emailType: [],
			submitLoading: false,
			tableLoading: false,
			pagination: {
				pageSize: 10,
				total: 0
			}
		}
	},
	filters: {
		filterUserType(val) {
			if (val === 'user') {
				return '用户'
			} else {
				return '教员'
			}
		},
		filterStatus(val) {
			if (val === 'Open') {
				return '进行中'
			} else if (val === 'UserReply') {
				return '用户已回复'
			} else if (val === 'AdminReply') {
				return '管理员已回复'
			} else if (val === 'Close') {
				return '关闭'
			}
		}
	},
	created() {
		const parameter = this.queryParam
		this.loadData(parameter)
	},
	methods: {
		loadData(parameter) {
			this.tableLoading = true
			this.$http({
				url: 'admin/worksheet/list',
				method: 'get',
				params: parameter
			}).then(res => {
				this.tableLoading = false
				if (res.status === 'success') {
					const data = res.data
					this.tableData = data.list
					this.pagination.total = data.paginate.total
					this.pagination.current = data.paginate.current_page
				} else {
					this.notifacation.error({
						message: '系统繁忙,请刷新重试',
						description: ''
					})
				}
			})
		},
		handleTableChange(pagination) {
			var current = pagination.current
			const parameter = this.queryParam
			// 加入page
			parameter.page = current
			this.loadData(parameter)
			this.pagination = pagination
		},
		goWorkOrderDetails(id) {
			this.$router.push({ name: 'WorkOrderDetails', params: { id: id } })
		},
		handleSubmit(e) {
			// 重置查询条件，并重新请求
			e.preventDefault()
			const parameter = {}
			parameter.status = this.form.getFieldValue('status')
			this.submitLoading = true
			this.loadData(parameter)
			this.submitLoading = false
			this.$router.push({
				query: merge(this.$route.query, parameter)
			})
		},
		resetQuery() {
			// 重置查询条件，并重新请求
			this.form.setFieldsValue({
				status: ''
			})
			this.$router.push({
				query: merge({}, {})
			})
			this.submitLoading = true
			this.loadData()
			this.submitLoading = false
		}
	}
}
</script>
